import React from "react";
// import logoLightSvg from '../../assets/Images/Icon/mainLogo.svg';

const Loading = () => {
  return (
    <React.Fragment>
      <div
        className="d-flex justify-content-center align-items-center "
        style={{ height: "100vh" }}
      >
        <i
          className="bx bx-loader bx-spin fs-3"
          style={{ color: "#ff5a13" }}
        ></i>
      </div>
    </React.Fragment>
  );
};

export default Loading;
